import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./themes/theme";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AppsIcon from '@mui/icons-material/Apps';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReactComponent as DatabitesLogo } from './images/logo_dashboard.svg';
import { ReactComponent as DatabitesLogoSmall } from './images/logo_dashboard_small.svg';
import Avatar from '@mui/material/Avatar';
import './App.css';
import ButtonGroup from '@mui/material/ButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const drawerWidth = 256;

// table data
function createData(name, location, turnover, score, sixmonths, previousyear, customers, scorek, bon) {
  return { name, location, turnover, score, sixmonths, previousyear, customers, scorek, bon };
}
const rows = [
  createData('Max Mustermann', 159, 6.0, 24, 4.0, 24, 4.0, 6.0),
  createData('Max Mustermann', 237, 9.0, 37, 4.3, 37, 4.3, 6.0),
  createData('Max Mustermann', 262, 16.0, 24, 6.0, 24, 6.0, 6.0),
  createData('Max Mustermann', 305, 3.7, 67, 4.3, 67, 4.3, 6.0),
  createData('Max Mustermann', 356, 16.0, 49, 3.9, 49, 3.9, 6.0),
];

// menu open/close
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  }
  return (
    <ThemeProvider theme={appTheme}>
    <Box className="db_app">
      <CssBaseline />
      <Drawer variant="permanent" open={open} className="db_sidebar">
        <Box className="db_sidbar_head">
          {open ? <DatabitesLogo className='db_logo' /> : <DatabitesLogoSmall className='db_logo db_logo_small' />}
          <Typography
            variant="h4"
            className="db_sidebar_headline"
            sx={{ display: open ? "block" : "none" }}>
            Data Analytics
          </Typography>
          <List className="db_sidbar_menu">
            {['Überblick', 'Performance', 'Personal', 'Ware'].map((text, index) => (
              <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className="db_sidbar_foot" sx={{
                  flexDirection: open ? 'row' : 'column',
            }}>
          
          {open ? <Button
            className="db_all_apps_button"
            variant="outlined"
            startIcon={<AppsIcon />}
            >
            <Typography sx={{ opacity: open ? 1 : 0 }}>Data Apps</Typography>
          </Button> : <IconButton><AppsIcon /></IconButton>}
          
          <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>
      </Drawer>
      <Box component="main" className="db_main">
        <DrawerHeader />
        <Box className='db_main_header'>
          <Typography variant="h1"> 
            Performance
          </Typography>
          <Box className="db_main_header_toolbar">
            <FormControl>
                <InputLabel id="db_location_label">Filiale</InputLabel>
                <Select
                  labelId="db_location_label"
                  id="db_location_select"
                  value={"Hamburg"}
                  label="Filiale"
                  onChange={() => {console.log("test")}}
                >
                  <MenuItem value={"Hamburg"}>Hamburg</MenuItem>
                  <MenuItem value={"Berlin"}>Berlin</MenuItem>
                  <MenuItem value={"Köln"}>Köln</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Zeitraum Start" />
                <DatePicker label="Zeitraum Ende" />
              </LocalizationProvider>
            </Box>
        </Box>
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua.
        </Typography>
        <Box className="db_data_boxes">
          <Box className="db_data_box">
              <h4>Umsatz</h4>
              <ul>
                <li><strong>VM</strong><span>+8,3%</span></li>
                <li><strong>6M</strong><span>+23,3%</span></li>
                <li><strong>VJ</strong><span>-4,2%</span></li>
              </ul>
          </Box>
          <Box className="db_data_box">
              <h4>Umsatz</h4>
              <ul>
                <li><strong>VM</strong><span>+8,3%</span></li>
                <li><strong>6M</strong><span>+23,3%</span></li>
                <li><strong>VJ</strong><span>-4,2%</span></li>
              </ul>
          </Box>
          <Box className="db_data_box">
              <h4>Umsatz</h4>
              <ul>
                <li><strong>VM</strong><span>+8,3%</span></li>
                <li><strong>6M</strong><span>+23,3%</span></li>
                <li><strong>VJ</strong><span>-4,2%</span></li>
              </ul>
          </Box>
          <Box className="db_data_box">
              <h4>Umsatz</h4>
              <ul>
                <li><strong>VM</strong><span>+8,3%</span></li>
                <li><strong>6M</strong><span>+23,3%</span></li>
                <li><strong>VJ</strong><span>-4,2%</span></li>
              </ul>
          </Box>
        </Box>

      <TableContainer component={Paper} className="db_table">
          <Typography variant="h4">Übersicht Performance</Typography>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Verkaufsgebiet</TableCell>
                  <TableCell align="right">Umsatz</TableCell>
                  <TableCell align="right">Score</TableCell>
                  <TableCell align="right">6M</TableCell>
                  <TableCell align="right">VJ</TableCell>
                  <TableCell align="right">Kundenanzahl</TableCell>
                  <TableCell align="right">Score K</TableCell>
                  <TableCell align="right">Kundenbon</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.location}</TableCell>
                    <TableCell align="right">{row.turnover}</TableCell>
                    <TableCell align="right">{row.score}</TableCell>
                    <TableCell align="right">{row.sixmonths}</TableCell>
                    <TableCell align="right">{row.previousyear}</TableCell>
                    <TableCell align="right">{row.customers}</TableCell>
                    <TableCell align="right">{row.scorek}</TableCell>
                    <TableCell align="right">{row.bon}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
            
        <Button color="primary" variant="contained">
          Primary
        </Button>
      </Box>
    </Box>
    </ThemeProvider>
  );
}