import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import MontserratMedium from './../fonts/Montserrat-Medium.ttf';
import MontserratBold from './../fonts/Montserrat-Bold.ttf';
import MontserratItalic from './../fonts/Montserrat-Italic.ttf';
import MontserratBoldItalic from './../fonts/Montserrat-BoldItalic.ttf';
import MontserratRegular from './../fonts/Montserrat-Regular.ttf';

const db_green = '#C3D98C';
const db_green_light = '#D1E69B';
const db_green_dark = '#B6CB83';
const db_olive = '#95A78D';
const db_olive_light = '#A0B79';
const db_olive_dark = '#84967B';
const db_petrol = '#486F71';
const db_petrol_light = '#5E827B';
const db_petrol_dark = '#3A5B5D';
const db_grey_light = '#F5F5F5';
const db_grey_dark = '#575756';
const db_mint = '#E7EBE5';
 
export const appTheme = createTheme({
  palette: {
    primary: {
      main: db_petrol,
      light: db_petrol_light,
      dark: db_petrol_dark,
    },
    secondary: {
      main: db_olive,
      light: db_olive_light,
      dark: db_olive_dark,
    }
  },
  typography: {
    fontFamily: "Montserrat, Roboto, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${MontserratRegular}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
          @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${MontserratMedium}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: bold;
          src: url(${MontserratBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Montserrat';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: url(${MontserratItalic}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        } 
        @font-face {
          font-family: 'Montserrat';
          font-style: italic;
          font-display: swap;
          font-weight: bold;
          src: url(${MontserratBoldItalic}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        } 
      `,
    },
  },
});